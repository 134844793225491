<template>
  <v-container fluid tag="section">
    <a class="allocation-formalization-back" @click="$router.go(-1)">
      <font-awesome-icon
        icon="fa-solid fa-arrow-left"
        class="allocation-formalization-back-icon"
      />
      <span
        class="allocation-formalization-back-text"
        data-test="Dashboard:SignContracts:Formalization"
        >{{ $t("formalization_title") }}</span
      >
    </a>
    <h3 class="page-subtitle" v-if="showAllocationData">
      {{ $t("formalization_subtitle") }}
    </h3>
    <v-card class="company-card mx-auto" max-width="348" v-if="gs.isMobile()">
      <v-card-actions style="max-height: 35px" class="pa-2">
        <h3 class="company-title pl-2">{{ allocation.Company.Name }}</h3>
        <v-spacer></v-spacer>
        <h4 class="company-action">
          {{ show ? $t("close_summary") : $t("open_summary") }}
        </h4>
        <v-btn icon @click="show = !show" color="primary">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-card-text>
            <p class="company-content mb-2">
              {{ convert_date(allocation.Timestamp) }}
            </p>
            <p class="company-content mb-2">
              {{ formatToCurrency(allocation.Currency, allocation.Value) }}
            </p>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>

    <v-card class="company-card mx-auto pa-2" v-else>
      <v-simple-table class="bg-white">
        <template v-slot:default>
          <thead>
            <th class="text-left">
              <h3 class="company-title pl-2">{{ allocation.Company.Name }}</h3>
            </th>
            <th class="text-left">
              <p class="company-content ma-1" style="font-size: 18px">
                {{ convert_date(allocation.Timestamp) }}
              </p>
            </th>
            <th class="text-left">
              <p class="company-content ma-1" style="font-size: 18px">
                {{ formatToCurrency(allocation.Currency, allocation.Value) }}
              </p>
            </th>
          </thead>
        </template>
      </v-simple-table>
    </v-card>
    <div
      v-if="showAllocationData"
      data-test="Dashboard:SignContracts:AllocationData"
    >
      <v-card class="main-card pa-2" v-if="gs.isMobile()">
        <!-- LLC - CLOSING -->
        <v-card
          color="white"
          class="content-card pa-2 mx-1 my-6"
          v-if="
            allocation.InvestmentVehicle.ClosingType ===
            vehicleClosingOptionsEnum.CLOSING_LLC
          "
        >
          <v-row>
            <v-col cols="6" class="pa-0 pl-3">
              <div
                v-if="allocation.SubscriptionAgreementSignatureDate"
                class="content-date"
                data-test="Dashboard:SignContracts:SubscriptionAgreementSignatureDate"
              >
                {{ $t("sign_date") }}:
                {{
                  convert_date(allocation.SubscriptionAgreementSignatureDate)
                }}
              </div>
              <div class="content-title">
                {{ $t("subscription_agreement") }}
              </div>
              <div
                class="content-status"
                v-if="!allocation.SignedSubscriptionAgreement"
                data-test="Dashboard:SignContracts:UnsignedSubscriptionAgreement"
              >
                {{ $t("not_signed") }}
              </div>
              <div
                class="content-status"
                style="color: var(--dark)"
                data-test="Dashboard:SignContracts:SignedSubscriptionAgreement"
                v-else
              >
                {{ $tc("signed", 1) }}
              </div>
            </v-col>
            <v-col cols="6" style="margin: auto; text-align: end">
              <div
                class="btn-container"
                v-if="!allocation.SignedSubscriptionAgreement"
              >
                <DxaButtonToTable
                  :disabled="!allocation.SubscriptionAgreementSignatureKey"
                  @btnFunction="
                    open_dialog(
                      allocation.SubscriptionAgreementSignatureKey,
                      docTypeEnum.SubscriptionAgreement
                    )
                  "
                  data-test="Dashboard:SignContracts:SignSubscriptionAgreement"
                  :title="$t('sign')"
                />
              </div>
              <div
                class="btn-container"
                v-if="allocation.SubscriptionAgreementSaved"
              >
                <DxaButtonToTable
                  @btnFunction="
                    downloadDocuments(userFileEnums.SUBSCRIPTION_AGREEMENT)
                  "
                  :title="$t('download')"
                  data-test="Dashboard:SignContracts:DownloadSubscriptionAgreement"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>
        <!-- SA - CLOSING -->
        <v-card
          color="white"
          class="content-card pa-2 mx-1 my-6"
          v-if="
            allocation.InvestmentVehicle.ClosingType ===
            vehicleClosingOptionsEnum.CLOSING_SA
          "
        >
          <v-row
            v-if="
              allocation.InvestmentVehicle.ClosingType ===
              vehicleClosingOptionsEnum.CLOSING_SA
            "
          >
            <v-col cols="6" class="pa-0 pl-3">
              <div
                v-if="allocation.NewsletterSignatureDate"
                class="content-date"
                data-test="Dashboard:SignContracts:NewsletterSubscriptionSignatureDate"
              >
                {{ $t("sign_date") }}:
                {{ convert_date(allocation.NewsletterSignatureDate) }}
              </div>
              <div class="content-title">
                {{ $t("newsletter_subscription") }}
              </div>
              <div
                class="content-status"
                v-if="!allocation.SignedNewsletterSubscription"
                data-test="Dashboard:SignContracts:UnsignedNewsletterSubscription"
              >
                {{ $t("not_signed") }}
              </div>
              <div
                class="content-status"
                style="color: var(--dark)"
                data-test="Dashboard:SignContracts:SignedNewsletterSubscription"
                v-else
              >
                {{ $tc("signed", 1) }}
              </div>
            </v-col>
            <v-col cols="6" style="margin: auto; text-align: end">
              <div
                class="btn-container"
                v-if="!allocation.SignedNewsletterSubscription"
              >
                <DxaButtonToTable
                  :disabled="!allocation.NewsletterSubscriptionSignatureKey"
                  @btnFunction="
                    open_dialog(
                      allocation.NewsletterSubscriptionSignatureKey,
                      docTypeEnum.NewsletterSubscription
                    )
                  "
                  data-test="Dashboard:SignContracts:SignNewsletterSubscription"
                  :title="$t('sign')"
                />
              </div>
              <div
                class="btn-container"
                v-if="allocation.NewsletterSubscriptionSaved"
              >
                <DxaButtonToTable
                  @btnFunction="
                    downloadDocuments(userFileEnums.NEWS_LETTER_SUBSCRIPTION)
                  "
                  :title="$t('download')"
                  data-test="Dashboard:SignContracts:DownloadNewsletterSubscription"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>
        <!-- SA - CLOSING -->
        <v-card
          color="white"
          class="content-card pa-2 mx-1 my-6"
          v-if="
            allocation.InvestmentVehicle.ClosingType ===
            vehicleClosingOptionsEnum.CLOSING_SA
          "
        >
          <v-row>
            <v-col cols="6" class="pa-0 pl-3">
              <div
                v-if="allocation.AdhesionTermSignatureDate"
                class="content-date"
                data-test="Dashboard:SignContracts:AdhesionTermSignatureDate"
              >
                {{ $t("sign_date") }}:
                {{ convert_date(allocation.AdhesionTermSignatureDate) }}
              </div>
              <div class="content-title">{{ $tc("adhesion_term", 1) }}</div>
              <div
                class="content-status"
                data-test="Dashboard:SignContracts:UnsignedAdhesionTerm"
                v-if="!allocation.SignedTerm"
              >
                {{ $t("not_signed") }}
              </div>
              <div
                class="content-status"
                style="color: var(--dark)"
                data-test="Dashboard:SignContracts:SignedAdhesionTerm"
                v-else
              >
                {{ $tc("signed", 1) }}
              </div>
            </v-col>
            <v-col cols="6" style="margin: auto; text-align: end">
              <div class="btn-container" v-if="!allocation.SignedTerm">
                <DxaButtonToTable
                  :disabled="!allocation.AdhesionTermSignatureKey"
                  @btnFunction="
                    open_dialog(
                      allocation.AdhesionTermSignatureKey,
                      docTypeEnum.AdhesionTerm
                    )
                  "
                  data-test="Dashboard:SignContracts:SignAdhesionTerm"
                  :title="$t('sign')"
                />
              </div>
              <div class="btn-container" v-if="allocation.AdhesionTermSaved">
                <DxaButtonToTable
                  @btnFunction="downloadDocuments(userFileEnums.ADHESION_TERM)"
                  :title="$t('download')"
                  data-test="Dashboard:SignContracts:DownloadAdhesionTerm"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>
        <!-- SA - CLOSING -->
        <v-card
          color="white"
          class="content-card pa-2 mx-1 my-6"
          v-if="
            allocation.InvestmentVehicle.ClosingType ===
            vehicleClosingOptionsEnum.CLOSING_SA
          "
        >
          <v-row>
            <v-col cols="6" class="pa-0 pl-3">
              <div class="content-date">-</div>
              <div class="content-title">{{ $tc("bylaws", 1) }}</div>
              <div class="content-status">-</div>
            </v-col>
            <v-col cols="6" style="margin: auto; text-align: end">
              <div
                class="btn-container"
                v-if="allocation.InvestmentVehicle.BylawsUrl"
              >
                <DxaButtonToTable
                  :disabled="!allocation.InvestmentVehicle.BylawsUrl"
                  @btnFunction="download_bylaws"
                  :title="$t('download')"
                  :loading="
                    fileDownloading == allocation.InvestmentVehicle.BylawsUrl
                  "
                  data-test="Dashboard:SignContracts:DownloadBylaws"
                ></DxaButtonToTable>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <!-- SA - CLOSING -->
        <v-card
          color="white"
          class="content-card pa-2 mx-1 my-6"
          v-if="
            allocation.InvestmentVehicle.ClosingType ===
            vehicleClosingOptionsEnum.CLOSING_SA
          "
        >
          <v-row>
            <v-col cols="6" class="pa-0 pl-3">
              <div class="content-date">-</div>
              <div class="content-title">
                {{ $tc("shareholders_agreement", 1) }}
              </div>
              <div class="content-status">-</div>
            </v-col>
            <v-col cols="6" style="margin: auto; text-align: end">
              <div
                class="btn-container"
                v-if="allocation.InvestmentVehicle.ShareholderAgreementUrl"
              >
                <DxaButtonToTable
                  :disabled="
                    !allocation.InvestmentVehicle.ShareholderAgreementUrl
                  "
                  @btnFunction="download_shareholder"
                  :title="$t('download')"
                  :loading="
                    fileDownloading ==
                    allocation.InvestmentVehicle.ShareholderAgreementUrl
                  "
                  data-test="Dashboard:SignContracts:DownloadShareholderAgreement"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>
        <!-- TABELA DADOS BANCÁRIOS -->
        <v-simple-table class="mt-3 bg-white">
          <thead id="investor-table-header" class="text-center">
            <tr>
              <th colspan="2" id="bank-table-title">
                {{ $t("bank_account_transfer_info") }}
              </th>
            </tr>
          </thead>
          <tbody id="bank-row">
            <tr class="content-row" v-for="(item, i) in bankInfo" :key="i">
              <td class="investor-bank-table-item" width="60px">
                {{ item.text }}
              </td>
              <td
                class="investor-bank-table-item investor-bank-table-item-value"
                v-if="item.value"
              >
                {{ item.value }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-card class="main-card pa-5" v-else>
        <v-simple-table class="mb-4 bg-white">
          <template v-slot:default>
            <thead id="investor-table-header" class="text-center">
              <tr>
                <th class="text-left">{{ $t("contracts") }}</th>
                <!-- <th class="text-left">{{ $t("sign_deadline") }}</th> -->
                <th class="text-left">{{ $t("sign_date") }}</th>
                <th class="text-left">{{ $t("status") }}</th>
                <th class="text-left">{{ $t("actions") }}</th>
              </tr>
            </thead>
            <tbody>
              <!-- CLOSING LLC - DOCUMENTS -->
              <tr
                class="content-row"
                v-if="
                  allocation.InvestmentVehicle.ClosingType ===
                  vehicleClosingOptionsEnum.CLOSING_LLC
                "
              >
                <td class="content-title">
                  {{ $t("subscription_agreement") }}
                </td>
                <!-- <td class="content-date">-</td> -->
                <td
                  v-if="allocation.SubscriptionAgreementSignatureDate"
                  class="content-date"
                  data-test="Dashboard:SignContracts:SubscriptionAgreementSignatureDate"
                >
                  {{
                    convert_date(allocation.SubscriptionAgreementSignatureDate)
                  }}
                </td>
                <td v-else class="content-date">-</td>
                <td
                  class="content-status"
                  v-if="!allocation.SignedSubscriptionAgreement"
                  data-test="Dashboard:SignContracts:UnsignedSubscriptionAgreement"
                >
                  {{ $t("not_signed") }}
                </td>
                <td
                  class="content-status"
                  style="color: var(--dark)"
                  data-test="Dashboard:SignContracts:SignedSubscriptionAgreement"
                  v-else
                >
                  {{ $tc("signed", 1) }}
                </td>
                <td>
                  <div
                    v-if="!allocation.SignedSubscriptionAgreement"
                    class="btn-container"
                  >
                    <DxaButtonToTable
                      :disabled="!allocation.SubscriptionAgreementSignatureKey"
                      @btnFunction="
                        open_dialog(
                          allocation.SubscriptionAgreementSignatureKey,
                          docTypeEnum.SubscriptionAgreement
                        )
                      "
                      data-test="Dashboard:SignContracts:SignSubscriptionAgreement"
                      :title="$t('sign')"
                    />
                  </div>
                  <div
                    v-if="allocation.SubscriptionAgreementSaved"
                    class="btn-container"
                  >
                    <DxaButtonToTable
                      @btnFunction="
                        downloadDocuments(userFileEnums.SUBSCRIPTION_AGREEMENT)
                      "
                      :title="$t('download')"
                      data-test="Dashboard:SignContracts:DownloadSubscriptionAgreement"
                    />
                  </div>
                </td>
              </tr>
              <!-- CLOSING SA - DOCUMENTS -->
              <tr
                class="content-row"
                v-if="
                  allocation.InvestmentVehicle.ClosingType ===
                  vehicleClosingOptionsEnum.CLOSING_SA
                "
              >
                <td class="content-title">
                  {{ $t("newsletter_subscription") }}
                </td>
                <!-- <td class="content-date">-</td> -->
                <td
                  v-if="allocation.NewsletterSignatureDate"
                  class="content-date"
                  data-test="Dashboard:SignContracts:NewsletterSubscriptionSignatureDate"
                >
                  {{ convert_date(allocation.NewsletterSignatureDate) }}
                </td>
                <td v-else class="content-date">-</td>
                <td
                  class="content-status"
                  v-if="!allocation.SignedNewsletterSubscription"
                  data-test="Dashboard:SignContracts:UnsignedNewsletterSubscription"
                >
                  {{ $t("not_signed") }}
                </td>
                <td
                  class="content-status"
                  style="color: var(--dark)"
                  data-test="Dashboard:SignContracts:SignedNewsletterSubscription"
                  v-else
                >
                  {{ $tc("signed", 1) }}
                </td>
                <td>
                  <div
                    v-if="!allocation.SignedNewsletterSubscription"
                    class="btn-container"
                  >
                    <DxaButtonToTable
                      :disabled="!allocation.NewsletterSubscriptionSignatureKey"
                      @btnFunction="
                        open_dialog(
                          allocation.NewsletterSubscriptionSignatureKey,
                          docTypeEnum.NewsletterSubscription
                        )
                      "
                      data-test="Dashboard:SignContracts:SignNewsletterSubscription"
                      :title="$t('sign')"
                    />
                  </div>
                  <div
                    v-if="allocation.NewsletterSubscriptionSaved"
                    class="btn-container"
                  >
                    <DxaButtonToTable
                      @btnFunction="
                        downloadDocuments(
                          userFileEnums.NEWS_LETTER_SUBSCRIPTION
                        )
                      "
                      :title="$t('download')"
                      data-test="Dashboard:SignContracts:DownloadNewsletterSubscription"
                    />
                  </div>
                </td>
              </tr>
              <tr
                class="content-row"
                v-if="
                  allocation.InvestmentVehicle.ClosingType ===
                  vehicleClosingOptionsEnum.CLOSING_SA
                "
              >
                <td class="content-title">{{ $tc("adhesion_term", 1) }}</td>
                <!-- <td class="content-date">-</td> -->
                <td
                  v-if="allocation.AdhesionTermSignatureDate"
                  class="content-date"
                  data-test="Dashboard:SignContracts:AdhesionTermSignatureDate"
                >
                  {{ convert_date(allocation.AdhesionTermSignatureDate) }}
                </td>
                <td v-else class="content-date">-</td>
                <td
                  class="content-status"
                  data-test="Dashboard:SignContracts:UnsignedAdhesionTerm"
                  v-if="!allocation.SignedTerm"
                >
                  {{ $t("not_signed") }}
                </td>
                <td
                  class="content-status"
                  style="color: var(--dark)"
                  data-test="Dashboard:SignContracts:SignedAdhesionTerm"
                  v-else
                >
                  {{ $tc("signed", 1) }}
                </td>
                <td>
                  <div v-if="!allocation.SignedTerm" class="btn-container">
                    <DxaButtonToTable
                      :title="$t('sign')"
                      :disabled="!allocation.AdhesionTermSignatureKey"
                      @btnFunction="
                        open_dialog(
                          allocation.AdhesionTermSignatureKey,
                          docTypeEnum.AdhesionTerm
                        )
                      "
                      data-test="Dashboard:SignContracts:SignAdhesionTerm"
                    />
                  </div>
                  <div
                    v-if="allocation.AdhesionTermSaved"
                    class="btn-container"
                  >
                    <DxaButtonToTable
                      :title="$t('download')"
                      @btnFunction="
                        downloadDocuments(userFileEnums.ADHESION_TERM)
                      "
                      data-test="Dashboard:SignContracts:DownloadAdhesionTerm"
                    />
                  </div>
                </td>
              </tr>
              <tr
                class="content-row"
                v-if="
                  allocation.InvestmentVehicle.ClosingType ===
                  vehicleClosingOptionsEnum.CLOSING_SA
                "
              >
                <td class="content-title">{{ $tc("bylaws", 1) }}</td>
                <!-- <td class="content-date">-</td> -->
                <td class="content-date">-</td>
                <td class="content-status">-</td>
                <td>
                  <div
                    v-if="allocation.InvestmentVehicle.BylawsUrl"
                    class="btn-container"
                  >
                    <DxaButtonToTable
                      :disabled="!allocation.InvestmentVehicle.BylawsUrl"
                      @btnFunction="download_bylaws"
                      :title="$t('download')"
                      :loading="
                        fileDownloading ==
                        allocation.InvestmentVehicle.BylawsUrl
                      "
                      data-test="Dashboard:SignContracts:DownloadBylaws"
                    />
                  </div>
                </td>
              </tr>
              <tr
                class="content-row"
                v-if="
                  allocation.InvestmentVehicle.ClosingType ===
                  vehicleClosingOptionsEnum.CLOSING_SA
                "
              >
                <td class="content-title">
                  {{ $tc("shareholders_agreement", 1) }}
                </td>
                <!-- <td class="content-date">-</td> -->
                <td class="content-date">-</td>
                <td class="content-status">-</td>
                <td>
                  <div
                    v-if="allocation.InvestmentVehicle.ShareholderAgreementUrl"
                    class="btn-container"
                  >
                    <DxaButtonToTable
                      :disabled="
                        !allocation.InvestmentVehicle.ShareholderAgreementUrl
                      "
                      @btnFunction="download_shareholder"
                      :title="$t('download')"
                      :loading="
                        fileDownloading ==
                        allocation.InvestmentVehicle.ShareholderAgreementUrl
                      "
                      data-test="Dashboard:SignContracts:DownloadShareholderAgreement"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div>
          <v-simple-table class="mt-3 bg-white">
            <thead id="investor-table-header" class="text-center">
              <tr>
                <th colspan="2">
                  {{ $t("bank_account_transfer_info") }}
                </th>
              </tr>
            </thead>
            <tbody id="bank-row">
              <tr class="content-row" v-for="(item, i) in bankInfo" :key="i">
                <td class="investor-bank-table-item" width="60px">
                  {{ item.text }}
                </td>
                <td
                  class="investor-bank-table-item investor-bank-table-item-value"
                >
                  {{ item.value }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card>
    </div>
    <div v-if="!showAllocationData" class="allocation-formalization-section">
      <div class="allocation-formalization-header">
        <span class="allocation-formalization-title">{{
          $t("formalization")
        }}</span>
      </div>
      <div class="allocation-formalization-subtitle">
        {{ $t("allocation_formalization") }}
      </div>
    </div>
    <v-dialog v-model="clickSignDialog" v-if="clickSignDialog">
      <v-card>
        <v-row justify="end" align="center" no-gutters class="py-2">
          <v-icon
            @click="reset_dialog"
            class="mr-3"
            data-test="Dashboard:SignContracts:CloseDialog"
            >mdi-close</v-icon
          >
        </v-row>
        <v-container>
          <div class="contract-div" style="margin-bottom: 4px">
            <iframe
              v-if="originalFile"
              :src="originalFile"
              width="100%"
              height="100%"
              margin-bottom="16px"
            ></iframe>
          </div>
          <div>
            <div id="cs-tokenless-widget"></div>
            <div>
              <v-btn
                id="submitButton"
                class="dxa-btn-primary mt-6"
                data-test="Dashboard:SignContracts:Submit"
                >{{ $t("send") }}</v-btn
              >
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";
import { formatCnpj } from "@/shared/helpers/cnpjHelper.js";
import DxaButtonToTable from "@/components/dxa/Button/DxaButtonToTable";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import banksData from "@/shared/data/banks.json";
import {
  AllocationFormalizationEnum,
  AllocationFormalizationFilledLoading,
} from "../../allocations/enums/AllocationFormalizationEnum";
import {
  vehicleClosingOptionsEnum,
  vehicleNationality,
} from "@/shared/enums/VehicleEnum";
import { userFileEnums } from "@/shared/enums/UserFileEnums";

export default {
  name: "AllocationSignContracts",
  components: {
    DxaButtonToTable,
  },
  computed: {
    docTypeEnum() {
      return {
        NewsletterSubscription: 1,
        AdhesionTerm: 2,
        SubscriptionAgreement: 3,
      };
    },
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    allocation: {},
    company: {},
    loading: false,
    filesLoading: false,
    clickSignDialog: false,
    docTypeSelected: null,
    selectedFiles: [],
    show: false,
    originalFile: "",
    bankInfo: [],
    showAllocationData: true,
    formatToCurrency: formatCurrency,
    vehicleClosingOptionsEnum,
    vehicleNationality,
    userFileEnums,
  }),

  async created() {
    this.loading = true;
    await this.get_allocation();
    this.loading = false;
  },
  watch: {
    "$i18n.locale"() {
      this.MountBankInfo();
    },
  },
  methods: {
    get_bank_name(acc) {
      let banks_filtered = banksData.filter((x) => {
        return x.COMPE == acc.Bank;
      });

      if (banks_filtered.length > 0) {
        return `${banks_filtered[0].COMPE} - ${banks_filtered[0].LongName}`;
      }
      return acc.Bank;
    },
    convert_date: function (date) {
      var m_date = moment(date);
      return m_date.format("DD/MM/YYYY");
    },
    files_changed(event) {
      this.selectedFiles = [];
      const files = Object.keys(event.target.files);
      files.forEach((file, index) => {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[file]);
        reader.onload = () => {
          this.selectedFiles.push({
            FileExtension: event.target.files[file].type.split("/")[1],
            Base64File: reader.result,
          });
        };
      });
    },
    reset_files() {
      this.$refs.file.value = null;
      this.selectedFiles = [];
    },
    async send_transfer_receipts() {
      var allocationId = parseInt(this.$route.params.allocationId);
      this.filesLoading = true;

      await this.apiService
        .postRequest(`companyinterest/send-transfer-receipts`, {
          Files: this.selectedFiles,
          AllocationId: allocationId,
        })
        .then((result) => {
          this.allocation.TransferReceiptSendingDate = result.content;
          if (this.selectedFiles.length > 1) {
            this.$toast.success(
              this.$tc("transfer_receipts_successfuly_sent", 2)
            );
          } else {
            this.$toast.success(
              this.$tc("transfer_receipts_successfuly_sent", 1)
            );
          }
          this.allocation.TransferReceiptSent = true;
          this.selectedFiles = [];
        })
        .catch((error) => {
          this.$toast.error(this.$t(error.body.message));
        });
      this.filesLoading = false;
    },

    download_receipt() {
      var allocationId = this.$route.params.allocationId;
      this.filesLoading = true;

      this.apiService
        .getRequest(`companyinterest/get-transfer-receipts/${allocationId}`)
        .then((resp) => {
          const fileName = resp.content.fileName;
          resp =
            "data:application/octet-stream;base64," + resp.content.fileContent;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          this.$toast.success(
            this.$tc("transfer_receipt_successfuly_download")
          );
        })
        .catch((error) => {
          if (error.body.statusCode == 404) {
            this.$toast.error(this.$t(error.body.message));
          }
        });
      this.filesLoading = false;
    },
    async downloadDocuments(fileType) {
      var allocationId = this.$route.params.allocationId;
      this.filesLoading = true;
      await this.apiService
        .getRequest(`/companyinterest/file/${allocationId}/${fileType}`)
        .then((resp) => {
          const fileName = resp.content.fileName;
          resp =
            "data:application/octet-stream;base64," + resp.content.fileContent;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          this.$toast.success(this.$tc("download_file_success"));
        })
        .catch((error) => {
          if (error.body.statusCode == 404) {
            this.$toast.error(this.$t(error.body.message));
          }
        });
      this.filesLoading = false;
    },
    download_bylaws() {
      this.filesLoading = true;
      this.apiService
        .getRequest(
          `investmentvehicle/document/${this.allocation.InvestmentVehicle.Id}/${AllocationFormalizationFilledLoading.BYLAWS}`
        )
        .then((resp) => {
          const fileName = resp.content.fileName;
          resp =
            "data:application/octet-stream;base64," + resp.content.fileContent;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          this.fileDownloading = null;
        })
        .catch((error) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.filesLoading = false;
    },
    download_shareholder() {
      this.filesLoading = true;
      this.apiService
        .getRequest(
          `investmentvehicle/document/${this.allocation.InvestmentVehicle.Id}/${AllocationFormalizationFilledLoading.SHARE_HOLDER_AGREEMENT}`
        )
        .then((resp) => {
          const fileName = resp.content.fileName;
          resp =
            "data:application/octet-stream;base64," + resp.content.fileContent;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          this.fileDownloading = null;
        })
        .catch((error) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.filesLoading = false;
    },
    checkIsInternClosing() {
      if (
        this.allocation.InvestmentVehicle.ClosingType ===
        this.vehicleClosingOptionsEnum.NO_CLOSING
      ) {
        this.showAllocationData = false;
      }
    },
    async get_allocation() {
      var allocationId = this.$route.params.allocationId;
      await this.apiService
        .postRequest(`companyinterest/allocation-contract-sign/${allocationId}`)
        .then((result) => {
          this.allocation = JSON.parse(result.message);
          this.checkIsInternClosing();
          this.MountBankInfo();
        })
        .catch((err) => {
          this.$toast.error(this.$t(err.body.message));
          this.$router.go(-1);
        });
    },
    async contract_signature_update(key) {
      var allocationId = this.$route.params.allocationId;
      await this.apiService
        .postRequest(
          `companyinterest/contract-signature-update/${allocationId}/${key}`
        )
        .then((result) => {
          this.allocation.SignedNewsletterSubscription =
            result.content.newsletter;
          this.allocation.SignedTerm = result.content.adhesionTerm;
          this.allocation.AdhesionTermSignatureDate =
            result.content.adhesionTermSignatureDate;
          this.allocation.NewsletterSignatureDate =
            result.content.newsletterSignatureDate;
          this.allocation.SubscriptionAgreementSignatureDate =
            result.content.subscriptionAgreementSignatureDate;
        })
        .catch((err) => {
          this.$toast.error(this.$t(err.body.message));
        });
    },
    async reset_dialog(key) {
      this.clickSignDialog = false;
      this.loading = true;
      if (
        key === this.allocation.AdhesionTermSignatureKey ||
        key === this.allocation.NewsletterSubscriptionSignatureKey ||
        key === this.allocation.SubscriptionAgreementSignatureKey
      ) {
        await this.contract_signature_update(key);
      }
      this.loading = false;
    },
    async open_dialog(key, docType) {
      this.docTypeSelected = docType;
      await this.get_original_doc();
      this.clickSignDialog = true;
      await new Promise((r) => setTimeout(r, 10));
      const submitButton = document.getElementById("submitButton");

      await this.send_custom_event();
      var tw = null;
      if (process.env.VUE_APP_CLICKSIGN_PROD === "TRUE") {
        tw = new tokenlessWidget(key);
      } else {
        tw = new tokenlessWidget(key, "sandbox");
      }

      tw.injectLoader('<div class="loader"></div>');

      tw.attach("click", "submitButton", () => {
        const data = {
          signature: {
            documentation: this.allocation.User.SocialNumber,
          },
        };
        tw.sign(data)
          .then((resp) => {
            this.reset_dialog(key);
          })
          .catch((error) => {
            this.$toast.error(error.message.base[0]);
          });
      });

      tw.mount({
        containerId: "cs-tokenless-widget",
        textTemplate: this.$t("read_agreed_contract"),
      });
    },
    async get_original_doc() {
      let documentKey = "";
      documentKey = this.allocation.AdhesionTermDocumentKey;
      if (this.docTypeSelected === this.docTypeEnum.AdhesionTerm) {
        documentKey = this.allocation.AdhesionTermDocumentKey;
      } else if (
        this.docTypeSelected === this.docTypeEnum.SubscriptionAgreement
      ) {
        documentKey = this.allocation.SubscriptionAgreementDocumentKey;
      } else {
        documentKey = this.allocation.NewsletterSubscriptionDocumentKey;
      }
      await this.apiService
        .getRequest(`companyinterest/get-original-doc/${documentKey}`)
        .then((result) => {
          this.originalFile = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            result.message
          )}`;
        })
        .catch((err) => {
          this.$toast.error(this.$t(err.body.message));
        });
    },

    async send_custom_event() {
      let documentKey = "";
      let allocationId = this.$route.params.allocationId;
      documentKey = this.allocation.AdhesionTermDocumentKey;
      if (this.docTypeSelected === this.docTypeEnum.AdhesionTerm) {
        documentKey = this.allocation.AdhesionTermDocumentKey;
      } else if (
        this.docTypeSelected === this.docTypeEnum.SubscriptionAgreement
      ) {
        documentKey = this.allocation.SubscriptionAgreementDocumentKey;
      } else {
        documentKey = this.allocation.NewsletterSubscriptionDocumentKey;
      }
      await this.apiService
        .postRequest(
          `companyinterest/send-custom-event/${allocationId}/${documentKey}`
        )
        .then((result) => {})
        .catch((err) => {
          this.$toast.error(this.$t(err.body.message));
        });
    },

    MountBankInfo() {
      if (
        this.allocation.InvestmentVehicle.VehicleNationality ===
        this.vehicleNationality.INTERNATIONAL
      ) {
        this.bankInfo = [
          {
            text: this.$t("value").concat(":"),
            value: this.formatToCurrency(
              this.allocation.Currency,
              this.allocation.Value
            ),
          },
          {
            text: this.$tc("company").concat(":"),
            value: this.allocation.InvestmentVehicle.Name,
          },
          {
            text: this.$tc("bank").concat(":"),
            value: this.allocation.InvestmentVehicle.InterBankAccounts[0].Bank,
          },
          {
            text: this.$tc("account").concat(":"),
            value:
              this.allocation.InvestmentVehicle.InterBankAccounts[0].Account,
          },
          {
            text: this.$tc("beneficiary").concat(":"),
            value:
              this.allocation.InvestmentVehicle.InterBankAccounts[0].Recipient,
          },
          {
            text: this.$tc("address").concat(":"),
            value:
              this.allocation.InvestmentVehicle.InterBankAccounts[0].Address,
          },
          {
            text: this.$tc("swift").concat(":"),
            value: this.allocation.InvestmentVehicle.InterBankAccounts[0].Swift,
          },
          {
            text: this.$tc("aba").concat(":"),
            value:
              this.allocation.InvestmentVehicle.InterBankAccounts[0]
                .RoutingNumber,
          },
          {
            text: this.$tc("other_informations").concat(":"),
            value:
              this.allocation.InvestmentVehicle.InterBankAccounts[0]
                .OtherInformation,
          },
        ];
      } else {
        this.bankInfo = [
          {
            text: this.$t("value").concat(":"),
            value: this.formatToCurrency(
              this.allocation.Currency,
              this.allocation.Value
            ),
          },
          {
            text: this.$tc("company").concat(":"),
            value: this.allocation.InvestmentVehicle.Name,
          },
          {
            text: this.$tc("company_id").concat(":"),
            value: this.allocation.InvestmentVehicle.Cnpj
              ? formatCnpj(this.allocation.InvestmentVehicle.Cnpj)
              : "",
          },
          {
            text: this.$tc("bank").concat(":"),
            value: this.get_bank_name(
              this.allocation.InvestmentVehicle.BankAccounts[0]
            ),
          },
          {
            text: this.$tc("agency").concat(":"),
            value: this.allocation.InvestmentVehicle.BankAccounts[0].Agency,
          },
          {
            text: this.$tc("account").concat(":"),
            value: this.allocation.InvestmentVehicle.BankAccounts[0].Account,
          },
          {
            text: "PIX",
            value: this.allocation.InvestmentVehicle.BankAccounts[0].Pix,
          },
        ];
      }
    },
  },
};
</script>
<style scoped>
#investor-table-header {
  background-color: var(--primary);
  border: 1px solid #ccc;
  border-collapse: collapse;
}

#investor-table-header > tr th {
  font-size: 1.2rem;
  color: var(--dark);
  font-weight: bold;
}

.content-loader tr td {
  white-space: nowrap;
}

#bank-row td {
  background: transparent;
  background-color: transparent;
  border-bottom: none !important;
  border: none !important;
  height: 20px !important;
  padding: 5px !important;
}

.page-title {
  font-size: 26px;
  color: var(--dark);
  font-family: "Source Sans Pro Bold";
  font-weight: bold;
}
.page-subtitle {
  font-size: 16px;
  color: #9c9c9c;
  font-family: "Source Sans Pro";
  font-weight: normal;
}
.company-card {
  background: var(--white);
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
  border-radius: 12px;
}
.company-title {
  font-size: 18px;
  color: var(--dark);
  font-family: "Source Sans Pro";
  font-weight: bold;
}
.company-action {
  font-size: 16px;
  color: #9c9c9c;
  font-family: "Source Sans Pro";
  font-weight: normal;
}
.company-content {
  font-family: "Source Sans Pro";
  font-weight: normal;
  color: #9c9c9c;
}
.main-card {
  background: var(--white);
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
  border-radius: 12px;
}
.content-card {
  /* border: 0.5px solid #9c9c9c; */
  box-sizing: border-box;
  border-radius: 12px;
}
.content-date {
  font-size: 12px;
  font-weight: 500;
  font-family: "Source Sans Pro";
  color: var(--dark);
}
.content-title {
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 16px;
  color: var(--primary);
  text-decoration: underline;
}
.content-status {
  font-family: "Source Sans 3 Light";
  font-weight: 600;
  font-size: 14px;
  color: var(--dark);
}
.action-btn {
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-size: 14px;
  color: var(--primary);
  border: 1px solid var(--primary);
  box-sizing: border-box;
  border-radius: 12px;
}
.table-head {
  background-color: var(--primary);
  /* border-radius: 12px; */
}
.content-row {
  background: transparent;
  background-color: transparent;
}
.content-row:hover {
  background: transparent !important;
  background-color: transparent !important;
}
.text-left {
  font-family: "Source Sans 3";
  font-weight: 600;
  font-size: 16px !important;
  color: rgba(45, 48, 56, 0.5);
}
.input-file {
  z-index: -1;
  position: absolute;
}
.signature-icon {
  width: 18px;
  margin-right: 8px;
}

.action-clicksign {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contract-div {
  overflow: hidden !important;
  height: 70vh;
}

#cs-tokenless-widget {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

#submitButton {
  margin-top: 8px !important;
  margin-bottom: 4px !important;
}

.investor-bank-table-item {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.investor-bank-table-item-value {
  font-weight: 600;
}

.btn-container {
  max-width: 170px;
  height: 40px;
}

.allocation-formalization-header {
  display: flex;
  background-color: var(--primary);
  width: 100%;
  padding: 16px;
}

.allocation-formalization-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.allocation-formalization-section {
  display: flex;
  flex-direction: column;
}

.allocation-formalization-subtitle {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.allocation-formalization-back {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
}

.allocation-formalization-back-icon {
  width: 22px;
  height: 22px;
  color: var(--primary);
}

.allocation-formalization-back-text {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

@media only screen and (max-width: 769px) {
  .contract-div {
    height: 50vh;
  }

  #bank-table-title {
    font-size: 14px !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
</style>
